const tournaments = Array();
const romanArr = ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X'];
let dailyTournaments;


async function getItems() {

    try {
        // https://tanki.su/tmsis/api/v1/landing/?filter%5Blanguage%5D=ru
        const response = await fetch('https://portal-mtoper.lstdev.net/tmsis/api/v1/landing/?filter%5Blanguage%5D=ru', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
        });
        const res = await response.json();

        console.log(res);
        
    } catch (error) {
        console.error("Ошибка при получении турниров:", error);
        throw error;
    }
}

function rulesPlayers (tournament) {

    const {min_players_in_team} = tournament?.summary;

    const result = `${min_players_in_team}X${min_players_in_team}`;

    return result;
}

function rulesLevels (tournament) {
    const min = tournament?.extra_data.allowed_vehicles_tier_from;
    const max = tournament?.extra_data.allowed_vehicles_tier_up_to;

    const convertMin = romanArr[min - 1];
    const convertMax = romanArr[max - 1];

    const level = convertMin === convertMax ? convertMin + ' Уровень' : convertMin + '-' + convertMax + ' Уровень';
    
    return level;
} 

function generateItems() {

    const list = document.createElement('div');
    list.className = 'daily-tournaments__list'

    tournaments && tournaments.forEach((tournament, index) => {

        const date = new Date(1697212800 * 1000);
        const day = date.getDate();
        const monthIndex = date.getMonth();
        const month = monthNamesShort[monthIndex];
        const startDate = day + " " + month;       

        console.log(tournament);

        const item = document.createElement('div');
        item.className = tournament.is_featured ? 'item item--featured' : 'item';

        const itemInner = document.createElement('div');
        itemInner.className = 'item__inner';
        item.appendChild(itemInner);

            const itemContentWrap = document.createElement('div');
            itemContentWrap.className = 'item__content';
            itemInner.appendChild(itemContentWrap);

                const itemLink = document.createElement('a');
                itemLink.href = `https://tanki.su/ru/tournaments/${tournament.id}/`;
                itemLink.target = '_blank';
                itemContentWrap.appendChild(itemLink);

                const itemImageWrap = document.createElement('div');
                itemImageWrap.className = 'image';
                itemContentWrap.appendChild(itemImageWrap);

                    if (tournament.is_featured) {
                        const isFeatured = document.createElement('p');
                        isFeatured.className = 'label';
                        isFeatured.innerText = 'важный';
                        
                        itemImageWrap.appendChild(isFeatured);
                    }

                    const itemImage = document.createElement('img');
                    itemImage.src = tournament?.files.logo_original;
                    itemImage.alt = tournament?.translations.title;
                    itemImageWrap.appendChild(itemImage);
            
                const itemContent = document.createElement('div');
                itemContent.className = 'content';
                itemContentWrap.appendChild(itemContent);
                    
                    const itemTitle = document.createElement('p');
                    itemTitle.className = 'title';
                    itemTitle.textContent = tournament?.translations.title;
                    itemContent.appendChild(itemTitle);

                    const itemDate = document.createElement('p');
                    itemDate.className = 'date';
                    itemDate.textContent = startDate;
                    itemContent.appendChild(itemDate);

                    const itemInfo = document.createElement('div');
                    itemInfo.className = 'info';
                    itemContent.appendChild(itemInfo);

                        const itemPlayers = document.createElement('div');
                        itemPlayers.className = 'info__item players';
                        itemInfo.appendChild(itemPlayers);

                            const itemPlayersIcon = `
                                <svg class="svg-icon users">
                                    <use xlink:href="./img/sprites.svg#users"></use>
                                </svg>
                                <span>${rulesPlayers (tournament)}</span>
                            `;

                            itemPlayers.innerHTML = itemPlayersIcon;

                        const itemLevels = document.createElement('div');
                        itemLevels.className = 'info__item levels';
                        itemInfo.appendChild(itemLevels);

                            const itemLevelsIcon = `
                                <svg class="svg-icon level">
                                    <use xlink:href="./img/sprites.svg#tank"></use>
                                </svg>
                                <span>${rulesLevels (tournament)}</span>
                            `;

                            itemLevels.innerHTML = itemLevelsIcon;

            const itemAsideWrap = document.createElement('div');
            itemAsideWrap.className = 'item__aside';
            itemInner.appendChild(itemAsideWrap);

                const itemAsideTitle = document.createElement('p');
                itemAsideTitle.className = 'title';
                itemAsideTitle.textContent = 'До конца регистрации: ';
                itemAsideWrap.appendChild(itemAsideTitle);

                const registrationEndTime = new Date(tournament.registrations[0].available_till * 1000).getTime();
                const currentTime = new Date().getTime();
                const timeRemaining = registrationEndTime - currentTime;

                const itemAsideCountDown = document.createElement('p');
                itemAsideCountDown.className = 'countdown';

                if (timeRemaining > 0) {
                    // Если осталось более 24 часов, отображаем количество дней
                    if (timeRemaining > 24 * 60 * 60 * 1000) {
                        const daysRemaining = Math.floor(timeRemaining / (24 * 60 * 60 * 1000));
                        itemAsideCountDown.textContent = daysRemaining >= 5 ? daysRemaining + ' дней' : daysRemaining + ' дня';
                    }
                    // Если осталось менее 24 часов, отображаем количество часов
                    else if (timeRemaining > 2 * 60 * 60 * 1000) {
                        const hoursRemaining = Math.floor(timeRemaining / (60 * 60 * 1000));
                        itemAsideCountDown.textContent = `${hoursRemaining} ч`;
                    }
                    // Если осталось менее 2 часов, отображаем часы и минуты и запускаем обратный отсчет
                    else {
                        const hoursRemaining = Math.floor(timeRemaining / (60 * 60 * 1000));
                        const minutesRemaining = Math.floor((timeRemaining % (60 * 60 * 1000)) / (60 * 1000));
                        if (hoursRemaining > 0) {
                            itemAsideCountDown.textContent = `${hoursRemaining} ч ${minutesRemaining} мин`;
                        } else {
                            itemAsideCountDown.textContent = `${minutesRemaining} мин`;
                        }
                        
                        itemAsideCountDown.className += 'countdown countdown--red';

                        // Запустить обратный отсчет
                        const countdownInterval = setInterval(() => {
                            const currentTime = new Date().getTime();
                            const timeRemaining = registrationEndTime - currentTime;

                            if (timeRemaining <= 0) {
                                itemAsideCountDown.textContent = 'Регистрация завершена';
                                clearInterval(countdownInterval);
                            } else {
                                const hoursRemaining = Math.floor(timeRemaining / (60 * 60 * 1000));
                                const minutesRemaining = Math.floor((timeRemaining % (60 * 60 * 1000)) / (60 * 1000));
                                if (hoursRemaining > 0) {
                                    itemAsideCountDown.textContent = `${hoursRemaining} ч ${minutesRemaining} мин`;
                                } else {
                                    itemAsideCountDown.textContent = `${minutesRemaining} мин`;
                                }
                                
                            }
                        }, 60000); // Обновляем каждую минуту
                    }
                } else {
                    itemAsideCountDown.textContent = 'Регистрация завершена';
                }

                itemAsideWrap.appendChild(itemAsideCountDown);

                const itemAsideLink = document.createElement('a');
                itemAsideLink.href = `https://tanki.su/ru/tournaments/${tournament.id}/registration/`;
                itemLink.target = '_blank';
                itemAsideLink.className = 'button button--outline';
                itemAsideLink.textContent = 'Участвовать';
                itemAsideWrap.appendChild(itemAsideLink);
                

        list.appendChild(item);
    })

    return list;

}

function initTournaments() {
    getItems()
        .then(res => {
            const template = generateItems();
            dailyTournaments.appendChild(template);
        }) 
}

window.addEventListener('load', function(e) {
    dailyTournaments = document.querySelector('.daily-tournaments__wrap');
    dailyTournaments && initTournaments();
});